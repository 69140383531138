@mixin flex-hor {
  align-items: center;
  display: flex;
}

@mixin flex-ver {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-middle {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin multi-text-overflow($line: 2) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $line; /* 라인수 */
  // line-height:1.2;
  // max-height: calc(1.2 * $line)rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: normal;
}

@mixin mobile-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.response-frame {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  > iframe {
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin layout-trans {
  transition-duration: 0.15s;
  transition-property: width, transform;
  transition-timing-function: linear;
}

@mixin layout-trans-padding {
  transition-duration: 0.15s;
  transition-property: padding-left;
  transition-timing-function: linear;
}
