// modal
.modal {
  background-color: rgba(0, 0, 0, 60%);

  .modal-dialog {
    &.modal-900 {
      margin-left: auto;
      margin-right: auto;
      max-width: 57.5rem;
    }

    &.modal-xxl {
      margin-left: 2rem;
      margin-right: 2rem;
      max-width: 100%;
    }
  }

  .modal-content {
    padding: 2.1875rem 1.875rem;

    .modal-header {
      border-bottom: 0.125rem solid var(--gray-color-300);
      color: var(--gray-color-900);
      font-weight: 700;
      padding: 0 0 1.875rem;

      .modal-title {
        font-size: 1.625rem;
      }

      .btns-close {
        i {
          color: var(--gray-color-500);
          font-size: 1.875rem;
          position: relative;
          top: 0.0625rem;
        }
      }
    }

    .modal-body {
      padding: 1.875rem 0;

      .body-cont {
        border: 1px solid var(--gray-color-300);
        border-radius: 1rem;
        color: var(--gray-color-600);
        font-size: 1rem;
        font-weight: 400;
        max-height: 20rem;
        overflow-y: auto;
        padding: 1.625rem 1.5rem;

        p {
          line-height: 1.2;
        }
      }
    }

    .modal-footer {
      border-top: 0.0625rem solid var(--gray-color-300);
      flex-shrink: initial;
      flex-wrap: initial;
      padding: 1.875rem 0 0;

      button {
        width: 100%;
      }
    }

    h6 {
      color: var(--gray-color-800);
      font-size: 1rem;
      font-weight: 400;
    }
  }

  &.head-line {
    .modal-header {
      border-bottom-color: var(--primary-color-400);
    }

    .modal-body {
      padding: 0 0 1.875rem;

      .body-head {
        background-color: var(--gray-color-50);
        margin-bottom: 1.875rem;
        min-height: 5.125rem;
        padding: 1.25rem 0.75rem;
        position: sticky;
        top: 0;

        &.txt-list {
          display: flex;

          .txt-wrap {
            margin-right: 1.5rem;
            padding-left: 1.5rem;
            position: relative;
            word-break: break-all;

            &:before {
              background-color: var(--gray-color-300);
              content: "";
              display: inline-block;
              height: 1rem;
              left: 0;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 0.0625rem;
            }

            &:first-child {
              flex: 1;
              padding-left: 0;

              &:before {
                content: none;
              }
            }

            &:nth-child(3) {
              max-width: 8rem;
            }

            &:last-child {
              margin-right: 0;
            }

            h6 {
              font-weight: 600;
            }

            p {
              color: var(--gray-color-900);
            }
          }
        }
      }
    }
  }
}

.modal-backdrop.show {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

// .modal:nth-of-type(even) {
//   z-index: 1062 !important;
// }
// .modal-backdrop.show:nth-of-type(even) {
//   z-index: 1061 !important;
// }

/*
* responsive
*/

// @media (width <= 1399.98px) {
// }

// @media (width <= 1199.98px) {
// }

@media (width <= 991.98px) {
  .modal {
    .modal-dialog {
      &.modal-900 {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
  }
}

@media (width <= 767.98px) {
  .modal {
    &.head-line {
      .modal-body {
        .body-head {
          &.txt-list {
            .txt-wrap {
              padding-left: 0;

              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (width <= 575.98px) {
  .modal {
    .modal-dialog {
      &.modal-900 {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
