h1 {
  font-size: 2.625rem;
  font-weight: 700;
}

h2 {
  font-size: 1.625rem;
  font-weight: 700;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

h4 {
  font-size: 1.125rem;
  font-weight: 500;
}

h5 {
  font-weight: 400;

  &.lg {
    font-size: 1.125rem;
  }

  &.md {
    font-size: 1rem;
  }

  &.sm {
    font-size: 0.875rem;
  }

  &.x-sm {
    font-size: 0.75rem;
  }
}
