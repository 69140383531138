.nav-bar-wrap {
  color: var(--gray-color-600);
  overflow: hidden;

  .logo-cont {
    align-items: center;
    display: flex;
    height: 7.5rem;
    justify-content: space-between;
    padding-left: 2.125rem;
    padding-right: 2.125rem;

    .logo {
      height: 1.625rem;
      max-width: 14rem;
      object-fit: cover;
      width: auto;
    }

    .btn-menu,
    .btn-n-close {
      > i {
        color: var(--black-color);
        display: block;
        font-size: 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    .btn-n-close {
      display: none;
    }
  }

  .link-items {
    padding: 1.5rem 1rem;

    li {
      .item {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        font-weight: 500;
        height: 4.625rem;
        overflow: hidden;
        padding: 0 1.5rem;

        > i {
          flex: none;
          font-size: 1.5rem;
          height: 1.5rem;
          margin-right: 1.5rem;
          width: 1.5rem;
        }

        > span {
          flex: none;
          overflow: hidden;
          transition: opacity 0s linear 0.15s;
        }

        &:after {
          color: var(--gray-color-400);
          content: "\e91f";
          display: block;
          flex: none;
          font-family: "hri_iconset", sans-serif;
          font-size: 1.5rem;
          margin-left: auto;
          transition: transform 0.06s linear;
        }

        &.alone:after {
          content: none;
        }

        &.active {
          background: linear-gradient(
            90.25deg,
            var(--primary-color-400) 2%,
            var(--primary-color-200) 98%
          );
          border-radius: 0.5rem;
          color: var(--white-color);
          transition: background-image 0s linear 0.15s;

          &:after {
            color: var(--white-color);
          }
        }

        &.open {
          &:after {
            transform: rotate(90deg);
          }

          + .sub-nav {
            max-height: 30rem;
          }
        }
      }

      .sub-nav {
        max-height: 0;
        overflow: hidden;
        padding: 0;
        transition: max-height 0.15s ease-in-out;

        li {
          line-height: 1;
          overflow: hidden;
          padding-left: 4.5rem;
          padding-right: 2rem;

          &:first-child {
            margin-top: 1rem;
          }

          &:last-child {
            margin-bottom: 1rem;
          }

          .sub-item {
            color: var(--gray-color-500);
            display: block;
            font-size: 1.125rem;
            font-weight: 500;
            overflow: hidden;
            padding-bottom: 0.563rem;
            padding-top: 0.563rem;

            &.active {
              color: var(--primary-color-400);
            }
          }
        }
      }
    }
  }
}

.fold {
  .nav-bar-wrap {
    .logo-cont {
      justify-content: center;

      .logo {
        display: none;
      }
    }

    .link-items {
      li {
        .item {
          span {
            opacity: 0;
          }

          &.active {
            background-color: var(--primary-color-400);
            background-image: none;
          }
        }

        .sub-nav {
          display: none;
        }
      }
    }
  }
}

/*
* responsive
*/

// @media (width <= 575.98px) {
// }

@media (width <= 767.98px) {
  .nav-bar {
    width: 100%;
  }
}

// @media (width <= 991.98px) {
// }

// @media (width <= 1199.98px) {
// }

@media (width <= 1399.98px) {
  .nav-bar {
    transform: translateX(-100%);
  }

  .nav-bar-wrap {
    .logo-cont {
      .btn-menu {
        display: none;
      }

      .btn-n-close {
        display: block;
      }
    }
  }

  .body-wrap {
    &.open {
      .nav-bar {
        transform: translateX(0);
      }
    }
  }
}
