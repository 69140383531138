/*
==========================================================================
  common
==========================================================================
*/

:root {
  overflow-y: auto;
  --font-montserrat: 'Montserrat', sans-serif;
  --font-noto-sans: 'Noto Sans KR', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0em;
}

html,
body {
  width: 100%;
  max-width: 100%;
  // overflow-x: hidden;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.2;
  // max-height: 100vh;
  
  font: {
    weight: 400;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
button,
textarea,
select,
a {
  font-family: inherit;
  color: inherit;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

ul,
ol,
dl,
dt,
dd {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

a,
button {
  text-decoration: none;
  transition: all 0.1s ease-in-out;
}

a:hover,
button:hover,
a:active,
button:active {
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}

a:active {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

/*모바일 스타일 제거*/
input[text],
textarea,
button {
  -webkit-appearance: none;
  appearance: none;
}

input[type='text'],
input[type='password'] textarea,
select {
  border-radius: 0;
  -webkit-appearance: none;
}

button {
  outline-style: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: 767.98px) {

}