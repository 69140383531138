#InfyLogin {
  min-height: 100vh;
  background: url('/images/login/bg_login.png') center/cover no-repeat;
  position: relative;
  color: #fff;

  .logo {
    display: block;
    width: 12.3125rem;
    height: 2.625rem;
    background: url('/images/common/img_infy_logo_white.png') center/cover no-repeat;
    position: absolute;
    top: 3.75rem;
    right: 5rem;
    z-index: 1;
  }

  .login-wrap {
    width: 41.875rem;
    min-height: 48.75rem;
    border-radius: 3.125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(12px);
    padding: 17.375rem 5rem 5.625rem;
    border: 3px solid rgba(57, 206, 226, 0.15);

    &::before {
      content: '';
      display: block;
      width: 12.3125rem;
      height: 9.25rem;
      background: url('/images/login/img_logo_lock.svg') center/cover no-repeat;
      position: absolute;
      top: 5.625rem;
      left: 50%;
      transform: translateX(-50%);
    }

    h2.text {
      font-size: 2.25rem;
      font-weight: 700;
      margin-bottom: 1.25rem;
    }

    .label {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .input-wrap {
      &.is-invalid {
        ~ .invalid-feedback {
          display: block;
          margin-top: 0.5rem;
        }
      }
    }

    .btn-eye {
      width: 1.5rem;
      height: 1.5rem;
      background: url('/images/login/icon_eye_on.svg') center/cover no-repeat;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;

      &.show {
        background-image: url('/images/login/icon_eye_off.svg');
      }
    }

    .btn-login {
      height: 4.625rem;
      background-color: #071d50;
      border-radius: 0.5rem;
      font-size: 1.375rem;
      font-weight: 700;
      padding: 0 1rem;

      &:hover {
        background-color: #051438;
      }
    }
  }

  .invalid-feedback {
    display: none;
    font-size: 0.875rem;
    font-weight: 400;
    color: #ffc32b;
  }

  .input-wrap {
    position: relative;

    input {
      width: 100%;
      height: 4.25rem;
      border: 1px solid #dedfdf;
      border-radius: 0.5rem;
      padding: 0 1rem;
      font-size: 1rem;
      color: #000;
      background-color: #fff;

      &::placeholder {
        opacity: 1;
        color: #b7b7b7;
        font-size: 1.125rem;
      }
    }
  }

  .checkbox {
    cursor: pointer;
    position: relative;
    display: inline-block;

    input {
      position: absolute;
      display: none;

      &[type='checkbox'] {
        &:checked {
          & ~ .label-checkbox {
            &::before {
              border-color: #00b2ff;
              background: #00b2ff url('/images/login/icon_checked.png') center/1.25rem no-repeat;
              box-sizing:initial;
            }
          }
        }
      }
    }

    .label-checkbox {
      display: flex;
      align-items: flex-start;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.2;

      &::before {
        content: '';
        display: inline-block;
        height: 1rem;
        width: 1rem;
        border: 1px solid #00b2ff;
        background-color: #fff;
        border-radius: 0.25rem;
        margin-right: 0.5rem;
        flex: none;
        box-sizing:initial;
      }
    }
  }
}

@media (max-height: 787.98px) {
  #InfyLogin {
    .login-wrap {
      position: relative;
      top: initial;
      transform: translate(-50%, 0);
    }
  }
}
