.project-management {
  .btns {
    margin-left: auto;
  }

  .card-items {
    height: auto;
  }

  .cont-row-wrap .cont-wrap .row:first-child .txt {
    padding-top: 0;
  }

  .cont-row-wrap .radio-wrap {
    margin-top: 8px;
  }

  .cont-btns-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .btns-wrap:last-child {
      text-align: end;
      width: 49%;

      .btns {
        margin-left: 4px;
      }
    }
  }
}

/*
* responsive
*/

// @media (max-width: 575.98px) {
// }

// @media (max-width: 767.98px) {
// }

// @media (max-width: 991.98px) {
// }

// @media (max-width: 1199.98px) {
// }

@media (width <= 1399.98px) {
  .tablehead-wrap {
    display: block;
  }
}
