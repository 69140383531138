.top-user-info {
  align-items: center;
  background-color: var(--white-color);
  display: flex;
  height: $header-height;
  justify-content: flex-end;
  padding-left: $base-md-padding;
  padding-right: $base-md-padding;

  .btn-menu {
    display: none;
    margin-right: auto;

    > i {
      color: var(--black-color);
      display: block;
      font-size: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .btn-profile {
    align-items: center;
    background: linear-gradient(
      90.25deg,
      var(--primary-color-400) 5%,
      var(--primary-color-200) 98%
    );
    border-radius: 0.5rem;
    color: var(--white-color);
    display: flex;
    flex: none;
    font-size: 1rem;
    font-weight: 600;
    // min-width: 12.75rem;
    height: 3.375rem;
    margin-right: calc(1.5rem - 0.1875rem);
    padding-left: 1.25rem;
    padding-right: 2.75rem;
    position: relative;

    .name {
      display: inline-block;
      max-width: 7.5rem;
      @include text-overflow;
    }
  }

  .profile-menu {
    display: none;
    inset: 0;
    overflow: hidden;
    position: fixed;
    z-index: 500;

    &.open {
      display: block;
    }

    .profile-list-wrap {
      background-color: var(--white-color);
      border-radius: 1rem;
      box-shadow: 0 0 8px rgba(0, 0, 0, 12%);
      max-width: 19.125rem;
      position: absolute;
      right: 3.75rem;
      top: $header-height;
      width: calc(100% - 2rem);
      z-index: 1000;

      .profile-header {
        align-items: center;
        display: flex;
        padding: 1.5rem 1.5rem 1.125rem;
        width: 100%;

        > h3 {
          color: var(--gray-color-900);
          font-size: 1.625rem;
          font-weight: 700;
        }

        .txt-wrap {
          color: var(--gray-color-500);
          font-size: 0.875rem;
          font-weight: 400;

          .name {
            color: var(--primary-color-400);
            font-size: 1.125rem;
            font-weight: 700;
            margin-right: 0.25rem;
          }

          .email {
            color: var(--gray-color-900);
          }
        }
      }

      .profile-list {
        .item {
          border-bottom: 1px solid var(--gray-color-200);

          a {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding: 1.125rem 1.875rem;
          }

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background-color: var(--primary-color-25);
          }

          i {
            color: var(--gray-color-500);
            font-size: 1rem;
            margin-right: 1.25rem;
          }

          .title {
            color: var(--gray-color-900);
            font-size: 1rem;
            font-weight: 600;
            @include text-overflow;
          }
        }
      }
    }
  }
}

.title-cont-wrap {
  align-items: center;
  background-color: var(--white-color);
  display: flex;
  padding: 2.125rem $base-md-padding;

  .title-container {
    align-items: flex-end;
    display: flex;
    padding: 12px 0;

    .title {
      color: var(--gray-color-900);
      flex: none;
      font-size: 2.625rem;
      font-weight: 700;
      line-height: 1;
      margin-right: 1.25rem;
    }

    .desc-wrap {
      .desc {
        color: var(--gray-color-500);
        flex: 1;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.35;
        margin-right: 1.25rem;
        overflow: hidden;
      }
    }
  }

  .btn-dashboard-set {
    align-items: center;
    background-color: var(--primary-color-400);
    border-radius: 0.5rem;
    color: var(--white-color);
    display: flex;
    flex: none;
    font-size: 1.25rem;
    font-weight: 600;
    height: 3.375rem;
    margin-left: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:after {
      content: "\e900";
      font-family: "hri_iconset", sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      margin-left: 0.75rem;
    }
  }
}

.breadcrumb {
  color: var(--gray-color-500);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3125rem;
  margin-bottom: 0;

  .breadcrumb-item {
    &.active {
      color: var(--primary-color-400);
      font-weight: 600;
    }
  }
}

/*
* responsive
*/

@media (width <= 575.98px) {
  .top-user-info {
    padding-left: $base-sm-padding;
    padding-right: $base-sm-padding;

    .btn-profile {
      .hello {
        display: none;
      }
    }

    .profile-modal {
      .profile-list-wrap {
        right: 1.75rem;
      }
    }
  }

  .title-cont-wrap {
    padding-left: $base-sm-padding;
    padding-right: $base-sm-padding;
  }
}

@media (width <= 767.98px) {
  .title-cont-wrap {
    .title-container {
      align-items: flex-start;
      flex-direction: column;

      .title {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (width <= 991.98px) {
  .title-cont-wrap {
    align-items: flex-start;
    flex-direction: column;

    .title-container {
      margin-bottom: 1rem;
    }
  }
}

// @media (width <= 1199.98px) {
// }

@media (width <= 1399.98px) {
  .top-user-info {
    .btn-menu {
      display: block;
    }
  }
}
