// button
.btns {
  background-color: var(--primary-color-400);
  border-radius: 0.5rem;
  color: var(--white-color);
  font-size: 1.25rem;
  font-weight: 600;
  height: 3.375rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  i {
    font-size: 1.25rem;
  }

  &:hover,
  &:active {
    background-color: var(--primary-color-600);
  }

  &:disabled {
    background-color: var(--primary-color-100);
    pointer-events: none;
  }

  &.btns-md {
    font-size: 1.125rem;
    height: 2.875rem;
    padding: 0 1.25rem;
  }

  &.btns-sm {
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 1.5rem;
    padding: 0 0.5rem;
  }

  // gray color
  &.btns-gray {
    background-color: var(--gray-color-200);
    color: var(--gray-color-500);

    &:hover,
    &:active {
      background-color: var(--gray-color-300);
    }

    &:disabled {
      background-color: var(--gray-color-100);
      opacity: 0.3;
    }
  }

  // icon-before
  &.btns-icon-before {
    align-items: center;
    display: flex;
    word-break: keep-all;

    i {
      margin-right: 0.5rem;
    }
  }

  // icon-after
  &.btns-icon-after {
    align-items: center;
    display: flex;
    word-break: keep-all;

    i {
      margin-left: 0.5rem;
    }
  }

  // outline
  &.btns-outline {
    background-color: var(--primary-color-25);
    border: 0.0625rem solid var(--primary-color-400);
    color: var(--primary-color-400);

    &:hover,
    &:active {
      border-color: var(--primary-color-600);
      color: var(--primary-color-600);
    }

    &:disabled {
      background-color: var(--primary-color-25);
      border-color: var(--primary-color-100);
      color: var(--primary-color-100);
    }
  }
}

// form
.checkbox-wrap,
.radio-wrap {
  cursor: pointer;
  line-height: 1;
  margin-right: 2rem;
  padding-left: 1.375rem;
  position: relative;

  input {
    display: none;
    position: absolute;

    // checkbox
    &[type="checkbox"] {
      &:checked {
        & ~ .label-checkbox {
          background: url("/images/component/icon_checked.png") no-repeat
            var(--primary-color-400);
          background-size: cover;
          border: none;
          display: inline-block;
          height: 1.125rem;
          width: 1.125rem;

          &:after {
            content: "";
          }
        }
      }
    }

    // radio
    &[type="radio"] {
      &:checked {
        & ~ .label-radio {
          border: 0.0625rem solid var(--gray-color-400);
          cursor: pointer;

          &:after {
            background-color: var(--primary-color-400);
          }
        }
      }
    }

    &:disabled {
      & ~ .label-radio {
        border: 0.0625rem solid var(--gray-color-400);
        cursor: default;
      }
    }
  }

  .label-checkbox {
    background-color: var(--white-color);
    border: 0.0625rem solid var(--gray-color-400);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 1.125rem;
    left: 0;
    position: absolute;
    top: -0.125rem;
    width: 1.125rem;

    &:after {
      content: "";
    }
  }

  .label-radio {
    background-color: var(--white-color);
    border: 0.0625rem solid var(--gray-color-400);
    border-radius: 50%;
    height: 1.125rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.125rem;

    &:after {
      background-color: var(--white-color);
      border-radius: 50%;
      content: "";
      height: 0.625rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.625rem;
    }
  }
}

// switch
.switch-wrap {
  height: 1rem;
  position: relative;
  width: 1.75rem;

  input {
    display: none;

    &:checked {
      & + .label-switch {
        background-color: var(--primary-color-400);

        &:before {
          left: calc(100% - 0.875rem);
        }
      }
    }

    &:focus {
      & + .label-switch {
        box-shadow: 0 0 0.0625rem var(--primary-color-400);
      }
    }
  }

  .label-switch {
    background-color: var(--gray-color-400);
    border-radius: 1.25rem;
    cursor: pointer;
    inset: 0;
    position: absolute;
    transition: 0.4s;

    &:before {
      background-color: var(--white-color);
      border-radius: 50%;
      bottom: 0.125rem;
      content: "";
      height: 0.75rem;
      left: 0.125rem;
      position: absolute;
      transition: 0.4s;
      width: 0.75rem;
    }
  }
}

// switch btns
.switch-btns-wrap {
  align-items: center;
  color: var(--gray-color-500);
  display: flex;
  font-size: 1.25rem;

  button {
    margin-bottom: 0;

    i {
      cursor: pointer;
      margin-left: 0.625rem;
    }
  }

  .btn-sort,
  .btn-open,
  .btn-copy {
    i {
      &:before {
        background: url("/images/homepage/icon_sort_gray.png") no-repeat
          center/cover;
        content: "";
        display: inline-block;
        height: 1.3125rem;
        position: relative;
        top: 0.125rem;
        width: 1.375rem;
      }
    }
  }

  .btn-open {
    i {
      &:before {
        background: url("/images/homepage/icon_accordion_open.png") no-repeat
          center/cover;
        top: 0.1875rem;
      }
    }
  }

  .btn-set {
    font-size: 1.1rem;

    i {
      margin-left: 0.75rem;
    }
  }

  .btn-copy {
    i {
      &:before {
        background: url("/images/homepage/icon_copy.png") no-repeat center/cover;
        top: 0.1875rem;
      }
    }
  }
}

// input
.txt {
  color: var(--gray-color-800);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.25rem 0 0.5rem;
}

.input-box {
  position: relative;

  &.calendar {
    align-items: center;
    // background: url("/images/component/ico_calendar.png") right 0.75rem center/
    //   1.25rem 1.25rem var(--white-color) no-repeat;
    border: 0.0625rem solid var(--gray-color-300);
    border-radius: 0.5rem;
    display: flex;
    height: 2.875rem;
    line-height: 1;
    padding-left: 1rem;
    // padding-right: 2.5rem;
    position: relative;
    width: 15.5rem;

    > input {
      background-color: transparent;
      border: none;
      flex: 1;
      height: 100%;
      padding: 0;
      text-align: center;
    }

    .separator {
      color: var(--gray-color-400);
      padding: 0 0.375rem;
    }
  }

  &.single-calendar {
    min-width: 9.5rem;

    > input {
      align-items: left;
      background: url("/images/component/ico_calendar.png") right 0.75rem
        center/ 1.25rem 1.25rem var(--white-color) no-repeat;
      border: 0.0625rem solid var(--gray-color-300);
      border-radius: 0.5rem;
      height: 2.875rem;
      line-height: 1;
      padding-left: 1rem;
      padding-right: 2.5rem;
      position: relative;
    }
  }

  &.password {
    input {
      padding: 0 3.8rem 0 1.125rem;
    }
  }

  input {
    background-color: var(--white-color);
    border: 0.0625rem solid var(--gray-color-300);
    border-radius: 0.5rem;
    color: var(--gray-color-700);
    height: 2.875rem;
    padding: 0 1.125rem;
    width: 100%;

    &::placeholder {
      color: var(--gray-color-400);
      font-size: 1rem;
      font-weight: 400;
    }

    &:disabled {
      background-color: var(--gray-color-100);
      border: 0.0625rem solid var(--gray-color-300);
    }
  }

  .btn-show,
  .btn-search {
    color: var(--gray-color-500);
    font-size: 1.5rem;
    position: absolute;
    right: 1.25rem;
    top: 15%;
  }

  .btn-search {
    font-size: 1.25rem;
    top: 19%;
  }

  &.search {
    input {
      padding: 0 3.5rem 0 1.125rem;
    }
  }
}

// input-login
.login {
  .input-box {
    margin-bottom: 0.875rem;

    input {
      height: 4.0625rem;
    }
  }

  .checkbox-wrap {
    padding-left: 1.5rem;
  }

  .is-invalid {
    border-color: var(--error-color-500);

    input {
      border-color: var(--error-color-500);
    }

    .invalid-feedback {
      display: block;
    }
  }
}

textarea {
  background-color: var(--white-color);
  border: 0.0625rem solid var(--gray-color-300);
  border-radius: 0.5rem;
  height: auto;
  padding: 0.875rem 1.125rem;
  width: 100%;
  word-break: break-all;

  &::placeholder {
    color: var(--gray-color-400);
    font-size: 1rem;
    font-weight: 400;
  }
}

.write-box {
  position: relative;

  textarea {
    padding-right: 4.5rem;
  }

  .byte {
    bottom: 1rem;
    color: var(--gray-color-800);
    font-size: 0.75rem;
    font-weight: 400;
    position: absolute;
    right: 1.25rem;

    .write-data {
      color: var(--primary-color-400);
      font-weight: 600;
    }
  }
}

// period-group
.period-group {
  align-items: center;
  background-color: var(--gray-color-200);
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;

  .select-peroid {
    color: var(--gray-color-600);
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;

    input {
      &[type="radio"] {
        opacity: 0;
        position: absolute;

        &:checked {
          & + .label-radio {
            background-color: var(--white-color);
            border-radius: 0.25rem;
            color: var(--gray-color-900);
            font-weight: 600;
          }
        }
      }
    }

    > .label-radio {
      align-items: center;
      display: flex;
      height: 2.375rem;
      justify-content: center;
      min-width: 4rem;
      text-align: center;

      &.btn-datemore {
        width: 5rem;

        &:after {
          background: url("/images/component/icon_arrow_top.png") no-repeat
            center/1rem;
          content: "";
          display: inline-block;
          height: 1rem;
          margin-left: 0.25rem;
          margin-top: 0.0625rem;
          width: 1rem;
        }

        &:focus {
          background-color: var(--white-color);
          border-radius: 0.25rem;
          color: var(--gray-color-900);
          font-weight: 600;
        }
      }
    }
  }
}

// selectbox
.select-box {
  select {
    appearance: none;
    background: url("/images/component/icon_arrow_top.png") no-repeat right
      1.125rem center/1.25rem;
    background-color: var(--white-color);
    border: 0.0625rem solid var(--gray-color-300);
    border-radius: 0.5rem;
    cursor: pointer;
    height: 2.875rem;
    line-height: 1;
    padding: 0 5.125rem 0 1.125rem;
    width: 100%;
  }
}

// dropdown
.dropdown-box {
  .dropdown-toggle {
    border-radius: 0.5rem;
    height: 2.875rem;
    line-height: 1;
    padding: 0.875rem 1.125rem;
    position: relative;
    text-align: left;

    &:after {
      content: none;
    }
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 10%);
    max-height: 14.375rem;
    overflow: auto;

    li {
      .dropdown-item {
        color: var(--gray-color-900);
        font-size: 1rem;

        &:active {
          background: var(--primary-color-400);
          color: var(--white-color);
        }

        &.active {
          background: none;
          font-weight: 600;
        }
      }
    }
  }
}

// pagination
.paging-wp {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  button {
    background-color: inherit;
    border: none;
    color: var(--gray-color-900);
    font-size: 1rem;
    font-weight: 400;
    height: 1.75rem;
    width: 1.75rem;

    &.active {
      background-color: var(--primary-color-25);
      border-radius: 0.5rem;
    }

    i {
      font-size: 1.125rem;
      position: relative;
      top: 0.125rem;
    }

    &.btn-prev10,
    &.btn-next10 {
      i {
        &:before {
          background: url("/images/component/icon_arrow_double_left.png")
            center/cover no-repeat;
          content: "";
          display: inline-block;
          height: 1.125rem;
          position: relative;
          top: 0.0625rem;
          width: 1.0625rem;
        }
      }
    }

    &.btn-next10 {
      i {
        &:before {
          background-image: url("/images/component/icon_arrow_double_right.png");
        }
      }
    }
  }
}

/* dash board */
.status-display {
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;

  .status-dp-icon {
    align-items: center;
    background-color: var(--primary-color-25);
    border-radius: 50%;
    display: flex;
    flex: none;
    height: 7.5rem;
    justify-content: center;
    margin-right: 2.375rem;
    width: 7.5rem;

    > i {
      color: var(--primary-color-400);
      font-size: 4rem;
    }
  }

  .status-dp-chart {
    margin-right: 2.375rem;
  }

  .status-dp-txt {
    flex: 1 1 auto;
    line-height: 1.2;
    overflow: hidden;

    .title {
      color: var(--gray-color-500);
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
    }

    .value {
      color: var(--gray-color-900);
      display: block;
      font-size: 1.625rem;
      font-weight: 500;

      .num {
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 1;
        margin-right: 0.3125rem;
        word-break: break-all;
      }

      .tag {
        background-color: var(--gray-color-500);
        border-radius: 0.25rem;
        color: var(--white-color);
        font-size: 1rem;
        font-weight: 600;
        margin-right: 0.5rem;
        padding: 0.125rem 0.25rem;
        position: relative;
        top: -0.25rem;
      }
    }

    .sub-title {
      color: var(--gray-color-500);
      font-size: 1rem;
      font-weight: 400;
      margin-top: 0.8125rem;

      .num {
        color: var(--primary-color-400);
        font-weight: 700;
        margin-left: 0.5rem;
      }

      &.label {
        margin-left: 0.5rem;
      }
    }

    .etc-info {
      color: var(--gray-color-400);
      display: block;
      font-size: 0.875rem;
      margin-top: 0.5rem;
    }

    &.flex {
      display: flex;
      flex-wrap: wrap;

      .txt-wrap {
        border-left: 1px solid var(--gray-color-300);
        text-align: center;
        width: calc(100% / 5);

        &:first-child {
          border-left: none;
        }
      }
    }

    &.status {
      .value {
        margin-bottom: 0.625rem;
      }
    }
  }

  &.primary {
    .status-dp-icon {
      background-color: var(--primary-color-25);

      > i {
        color: var(--primary-color-400);
      }
    }

    .status-dp-txt {
      .value {
        .num {
          &.color {
            color: var(--primary-color-400);
          }
        }

        .tag {
          background-color: var(--primary-color-600);
          word-break: keep-all;
        }
      }
    }
  }

  &.secondary {
    .status-dp-icon {
      background-color: var(--secondary-color-25);

      > i {
        color: var(--secondary-color-400);
      }
    }

    .status-dp-txt {
      .value {
        .num {
          &.color {
            color: var(--secondary-color-400);
          }
        }

        .tag {
          background-color: var(--secondary-color-600);
          word-break: keep-all;
        }
      }
    }
  }

  &.sm {
    .status-dp-txt {
      .value {
        font-size: 1rem;

        .num {
          font-size: 1.5rem;
        }
      }
    }
  }
}

// file upload
.file-upload {
  .sub-title-wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .sub-title {
      color: var(--gray-color-800);
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .file-desc {
    color: var(--primary-color-400);
    font-size: 0.75rem;
    font-weight: 600;

    &.desc {
      margin-top: 0.5rem;
    }

    &.caution {
      font-weight: 400;
      margin-left: 1.125rem;

      li {
        list-style: disc;
      }
    }
  }

  .dz-clickable {
    align-items: center;
    border: 1px dashed var(--gray-color-300);
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    height: 10rem;
    justify-content: center;

    .dz-msg {
      color: var(--gray-color-700);
      font-size: 0.75rem;
      font-weight: 400;
      pointer-events: none;
      text-align: center;

      em {
        font-weight: 600;
      }

      &:before {
        color: var(--gray-color-500);
        content: "\e912";
        display: inline-block;
        font-family: "hri_iconset", sans-serif;
        font-size: 1.875rem;
      }

      &.pdf {
        &:before {
          background: url("/images/content/icon_PDFupload.png") center/cover
            no-repeat;
          content: "";
          display: block;
          height: 1.875rem;
          margin: 0 auto;
          width: 1.875rem;
        }
      }
    }
  }

  .file-dropzone {
    &.single {
      position: relative;

      .img-thumb {
        border-radius: 0.5rem;
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .file-list {
    overflow: hidden;

    .dz-preview:first-of-type {
      margin-top: 0.5rem;
    }

    .dz-preview {
      align-items: center;
      background-color: var(--gray-color-50);
      border: 1px solid var(--gray-color-300);
      border-radius: 0.5rem;
      display: flex;
      height: 3rem;
      margin-top: 0.25rem;
      overflow: hidden;
      padding: 0 1.125rem;

      &:before {
        background: url("/images/content/icon_paperclip.png") center/cover
          no-repeat;
        content: "";
        display: block;
        flex: none;
        height: 1rem;
        margin-right: 0.375rem;
        width: 1rem;
      }

      .dz-image,
      .dz-error-mark,
      .dz-success-mark {
        display: none;
      }

      .dz-details {
        color: var(--gray-color-700);
        flex: 1;
        font-size: 0.875rem;
        font-weight: 600;
        overflow: hidden;
        padding-right: 0.375rem;

        .dz-size {
          display: none;
        }

        .dz-filename {
          @include text-overflow;
        }
      }

      .dz-error-message {
        color: var(--error-color-500);
        display: none;
        font-size: 0.75rem;
        padding-right: 0.375rem;
      }

      .dz-progress {
        background-color: var(--gray-color-300);
        border-radius: 0.25rem;
        display: none;
        flex: none;
        height: 0.5rem;
        margin-right: 0.375rem;
        position: relative;
        width: 5rem;

        > .dz-upload {
          background-color: var(--primary-color-400);
          border-radius: 0.25rem;
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transition: width 300ms ease-in-out;
        }
      }

      .dz-remove {
        align-items: center;
        color: var(--gray-color-500);
        display: none;
        font-size: 1rem;
        height: 1rem;
        justify-content: center;
        width: 1rem;

        > i {
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      &.dz-processing {
        .dz-progress {
          display: block;
        }
      }

      &.dz-error {
        .dz-error-message {
          display: block;
        }
      }

      &.dz-complete {
        .dz-progress {
          display: none;
        }

        .dz-remove {
          display: block;
        }
      }
    }
  }
}

// stepper
.stepper {
  display: flex;
  flex-wrap: wrap;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 50%;

  .stepper-item {
    color: var(--gray-color-400);
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;

    &:after {
      background-color: var(--primary-color-50);
      bottom: 81%;
      content: "";
      height: 0.125rem;
      left: 50%;
      position: relative;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    &.ing {
      color: var(--gray-color-900);

      .stepper-label {
        border-color: var(--primary-color-500);

        &:before {
          background-color: var(--primary-color-500);
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 0.625rem;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 0.625rem;
        }
      }
    }

    &.clear {
      color: var(--gray-color-400);

      .stepper-label {
        background: var(--primary-color-500)
          url("/images/component/icon_stepper.png") no-repeat center/0.9375rem;
        border: none;
      }

      &:after {
        background-color: var(--primary-color-500);
        bottom: 81%;
        content: "";
        height: 2px;
        left: 50%;
        position: relative;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  .stepper-title {
    font-size: 0.875rem;
  }

  .stepper-label {
    background-color: var(--white-color);
    border: 2px solid var(--primary-color-50);
    border-radius: 50%;
    height: 2rem;
    margin: 0 auto;
    margin-bottom: 0.75rem;
    position: relative;
    width: 2rem;
    z-index: 1;
  }
}

// smart editor (임시영역표시)
.smart-editor-wrap {
  border: 1px solid var(--gray-color-300);
  border-radius: 0.5rem;
  height: 10rem;
}

/*
* responsive
*/

@media (width <= 575.98px) {
  .status-display {
    .status-dp-txt {
      &.flex {
        .txt-wrap {
          width: calc(100% / 3);
        }
      }
    }
  }
}

@media (width <= 767.98px) {
  .stepper {
    width: 100%;
  }
}

// @media (width <= 991.98px) {
// }

// @media (width <= 1199.98px) {
// }

// @media (width <= 1399.98px) {
// }
