.tb-cont-manage {
  table {
    min-width: 95rem;

    .device-btn {
      .btns {
        display: block;
        min-width: 3.4375rem;

        &:first-child {
          margin-bottom: 0.25rem;
        }

        &:before {
          content: none;
        }
      }
    }
  }
}

.content-display {
  display: flex;
  // flex-wrap: wrap;
  gap: 2rem;

  .cont-list {
    min-width: 17rem;

    .register-list {
      border: 1px solid var(--gray-color-300);
      border-radius: 0.5rem;
      margin-bottom: 2rem;
      overflow: hidden;

      li {
        font-size: 1.125rem;
        position: relative;

        a {
          color: var(--gray-color-700);
          display: block;
          font-weight: 400;
          padding: 0.8125rem 3.375rem;
        }

        &:after {
          color: var(--gray-color-500);
          content: "\e918";
          cursor: pointer;
          font-family: "hri_iconset", sans-serif;
          position: absolute;
          right: 1rem;
          top: 0.75rem;
        }

        &:first-child {
          background-color: var(--gray-color-50);
          color: var(--gray-color-900);
          font-weight: 700;
          padding: 0.8125rem 3.375rem;

          &:before {
            background: url("/images/content/icon_category.png") center/cover
              no-repeat;
            content: "";
            display: inline-block;
            height: 1.5rem;
            left: 1.125rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1.5rem;
          }

          &:after {
            content: none;
          }

          .switch-wrap {
            position: absolute;
            right: 0.875rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    button {
      display: block;
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }

  .cont-row-wrap {
    flex-grow: 2;

    .row {
      --bs-gutter-x: 0.75rem;
    }

    .cont-wrap {
      padding-top: 2rem;

      &:first-child {
        padding-top: 0;
      }

      &:nth-last-child(2) {
        border-bottom: 1px solid var(--gray-color-300);
        margin-bottom: 2rem;
        padding-bottom: 2rem;
      }

      &.uploaded {
        &:nth-last-child(3) {
          border-bottom: 1px solid var(--gray-color-300);
          padding-bottom: 2rem;
        }
      }

      &.url {
        padding-top: 0;
      }
    }

    .list-title {
      background-color: var(--primary-color-25);
      border-radius: 0.5rem;
      color: var(--gray-color-900);
      font-size: 1rem;
      font-weight: 600;
      padding: 1.0625rem 1.125rem;
    }

    .col {
      .txt {
        padding: 1.5rem 0 0.5rem;
      }

      .num,
      .txt-desc {
        color: var(--gray-color-700);
        font-size: 1rem;
        font-weight: 400;
      }

      .select-wrap {
        display: flex;
        gap: 0.5rem;

        .select-box {
          flex: 1;

          select {
            padding: 0 3rem 0 1.125rem;
          }
        }

        button {
          flex-shrink: 0;
        }
      }

      .input-box-wrap {
        display: flex;
        gap: 0.5rem;

        .input-box {
          flex: 1;
        }
      }
    }

    .file-upload {
      padding-top: 1.5rem;
    }

    .btns-wrap {
      text-align: right;
    }
  }
}

.essential {
  &:after {
    content: "*";
    display: inline-block;
    margin-left: 0.0625rem;
  }
}

/*
* responsive
*/

@media (width <= 575.98px) {
  .content-display {
    .cont-row-wrap {
      .col {
        .select-wrap,
        .input-box-wrap {
          display: block;

          .select-box,
          .input-box {
            margin-bottom: 0.5rem;
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (width <= 767.98px) {
  .content-display {
    .cont-row-wrap {
      .cont-wrap {
        &.uploaded {
          .row-cols-2 {
            & > * {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (width <= 991.98px) {
  .content-display {
    display: block;

    .cont-list {
      .register-list {
        margin-bottom: 1rem;

        .list:not(:first-child),
        .btns-wrap {
          display: none;
        }

        .list {
          &:first-child {
            display: block;
          }
        }
      }

      .btns-wrap {
        display: none;
      }
    }

    .cont-row-wrap {
      .btns-wrap {
        text-align: center;
      }
    }
  }
}

@media (width <= 1199.98px) {
  .content-display {
    .row-cols-2,
    .row-cols-3 {
      & > * {
        width: 100%;
      }
    }

    .cont-wrap {
      &.uploaded {
        .row-cols-2 {
          & > * {
            width: 50%;
          }
        }
      }
    }
  }
}

// @media (width <= 1399.98px) {
// }
