.body-wrap {
  background-color: var(--gray-color-50);
  min-height: 100vh;

  &.fold {
    .nav-bar {
      width: $nav-fold-width;
    }

    .main-wrap {
      padding-left: $nav-fold-width;
    }

    header {
      padding-left: $nav-fold-width;
    }
  }
}

.nav-bar {
  background-color: var(--white-color);
  box-shadow: 1.125rem 0.25rem 2.1875rem rgba(0, 0, 0, 2%);
  height: 100vh;
  left: 0;
  overflow-y: overlay;
  position: fixed;
  top: 0;
  width: $nav-lg-width;
  z-index: 100;
  @include layout-trans;
}

@-moz-document url-prefix() {
  .nav-bar {
    overflow-y: auto;
  }
}

.main-wrap {
  overflow: hidden;
  padding-left: $nav-lg-width;
  padding-top: $header-height;
  @include layout-trans-padding;
}

header {
  height: $header-height;
  left: 0;
  padding-left: $nav-lg-width;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 50;
  @include layout-trans-padding;
}

main {
  .contents-wrap {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .container-fluid {
    margin-left: auto;
    margin-right: auto;
    max-width: 97.8125rem;
    padding-left: $base-md-padding;
    padding-right: $base-md-padding;
  }

  .row-group {
    margin-bottom: 0.5rem;
  }
}

.card-row {
  margin: 0 -1rem;

  > .col {
    padding: 1rem;
  }

  .card-items {
    background-color: var(--white-color);
    border-radius: 1rem;
    box-shadow: $card-item-shadow;
    height: 12rem;
    padding: 2rem;

    &.h-140 {
      height: 8.75rem;
    }

    &.h-300 {
      height: 18.75rem;
    }

    &.h-400 {
      height: 25rem;
    }

    &.h-460 {
      height: 28.75rem;
    }

    &.h-600 {
      height: 38rem;
    }

    &.h-640 {
      height: 40rem;
    }

    &.h-700 {
      height: 45rem;
    }

    &.h-auto {
      height: auto;
    }
  }
}

.row-title {
  color: var(--gray-color-900);
  font-size: 1.75rem;
  font-weight: 500;
}

/*
* responsive
*/

@media (width <= 575.98px) {
  html {
    font-size: 14px;
  }

  main {
    .container-fluid {
      padding-left: $base-sm-padding;
      padding-right: $base-sm-padding;
    }
  }
}

@media (width <= 767.98px) {
  .card-row {
    .card-items {
      overflow-x: auto;
    }
  }
}

@media (width <= 991.98px) {
  .row-title {
    margin-top: 1rem;
  }

  main {
    .row-group {
      margin-bottom: 0;
    }
  }
}

// @media (width <= 1199.98px) {
// }

@media (width <= 1399.98px) {
  .nav-bar {
    transform: translateX(-100%);
  }

  .main-wrap {
    padding-left: 0;
  }

  header {
    padding-left: 0;
  }
}
