@import "/src/styles/fonts/pretendard.css";
@import "/src/styles/fonts/hri_iconset.css";

$primary-font: "Pretendard", -apple-system, blinkmacsystemfont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
$card-item-shadow: 0 0.75rem 1.4375rem rgba(62, 73, 84, 4%);
$nav-lg-width: 21.25rem;
$nav-sm-width: 12.5rem;
$nav-fold-width: 6.625rem;
$header-height: 7.5rem;
$base-md-padding: 3rem;
$base-sm-padding: 1.25rem;

/*
root var
*/

:root,
html[data-color-theme="default"] {
  --primary-color-25: #f5faff;
  --primary-color-50: #eff8ff;
  --primary-color-100: #d1e9ff;
  --primary-color-200: #b2ddff;
  --primary-color-300: #84caff;
  --primary-color-400: #53b1fd;
  --primary-color-500: #2e90fa;
  --primary-color-600: #1570ef;
  --primary-color-700: #175cd3;
  --primary-color-800: #1849a9;
  --primary-color-900: #194185;
  --secondary-color-25: #f2fdff;
  --secondary-color-50: #eafbff;
  --secondary-color-100: #bbecf8;
  --secondary-color-200: #a4e7f8;
  --secondary-color-300: #86d9ea;
  --secondary-color-400: #56d0e9;
  --secondary-color-500: #2abfe8;
  --secondary-color-600: #1eb0d9;
  --secondary-color-700: #129ec5;
  --secondary-color-800: #0b92b8;
  --secondary-color-900: #007292;
  --gray-color-25: #fcfdfd;
  --gray-color-50: #f9f9f9;
  --gray-color-100: #f4f4f4;
  --gray-color-200: #eee;
  --gray-color-300: #dedfdf;
  --gray-color-400: #b7b7b7;
  --gray-color-500: #878787;
  --gray-color-600: #676767;
  --gray-color-700: #575757;
  --gray-color-800: #373737;
  --gray-color-900: #171717;
  --white-color: #fff;
  --black-color: #000;
  --error-color-25: #fffbfa;
  --error-color-50: #fef3f2;
  --error-color-400: #fa7066;
  --error-color-500: #f04438;
  --warning-color-25: #fffcf5;
  --warning-color-50: #fffaeb;
  --warning-color-400: #fdb022;
  --warning-color-500: #f79009;
  --success-color-25: #f6fef9;
  --success-color-50: #ecfdf3;
  --success-color-400: #32d583;
  --success-color-500: #12b76a;
  --info-color-25: #f6fbff;
  --info-color-50: #eff8ff;
  --info-color-400: #468ff6;
  --info-color-500: #1570ef;
  --bs-font-sans-serif: $primary-font;
  --bs-body-line-height: 1;
}

html[data-color-theme="creative-default"] {
  --primary-color-25: #fff2f4;
  --primary-color-50: #fbe3e6;
  --primary-color-100: #f4cad1;
  --primary-color-200: #f2b1bc;
  --primary-color-300: #ed8ea0;
  --primary-color-400: #f26d8a;
  --primary-color-500: #e93962;
  --primary-color-600: #d81c48;
  --primary-color-700: #c01048;
  --primary-color-800: #a11043;
  --primary-color-900: #790731;
  --secondary-color-25: #effcf8;
  --secondary-color-50: #e2faf3;
  --secondary-color-100: #c5f2e5;
  --secondary-color-200: #a7e9d4;
  --secondary-color-300: #78debe;
  --secondary-color-400: #65d2b5;
  --secondary-color-500: #3cd1aa;
  --secondary-color-600: #16bd91;
  --secondary-color-700: #0da87f;
  --secondary-color-800: #048f6b;
  --secondary-color-900: #016148;
  --gray-color-25: #fcfdfd;
  --gray-color-50: #f9f9f9;
  --gray-color-100: #f4f4f4;
  --gray-color-200: #eee;
  --gray-color-300: #dedfdf;
  --gray-color-400: #b7b7b7;
  --gray-color-500: #878787;
  --gray-color-600: #676767;
  --gray-color-700: #575757;
  --gray-color-800: #373737;
  --gray-color-900: #171717;
  --white-color: #fff;
  --black-color: #000;
  --error-color-25: #fffbfa;
  --error-color-50: #fef3f2;
  --error-color-400: #fa7066;
  --error-color-500: #f04438;
  --warning-color-25: #fffcf5;
  --warning-color-50: #fffaeb;
  --warning-color-400: #fdb022;
  --warning-color-500: #f79009;
  --success-color-25: #f6fef9;
  --success-color-50: #ecfdf3;
  --success-color-400: #32d583;
  --success-color-500: #12b76a;
  --info-color-25: #f6fbff;
  --info-color-50: #eff8ff;
  --info-color-400: #468ff6;
  --info-color-500: #1570ef;
  --bs-font-sans-serif: $primary-font;
}

html[data-color-theme="cylearn-default"] {
  --primary-color-25: #f2f1ff;
  --primary-color-50: #e1e1fb;
  --primary-color-100: #b6b5ee;
  --primary-color-200: #9896ea;
  --primary-color-300: #8682ea;
  --primary-color-400: #706ce8;
  --primary-color-500: #5651ea;
  --primary-color-600: #4540e9;
  --primary-color-700: #1812c5;
  --primary-color-800: #110bb2;
  --primary-color-900: #070288;
  --secondary-color-25: #ecf9fc;
  --secondary-color-50: #ddf7fd;
  --secondary-color-100: #bbecf8;
  --secondary-color-200: #a4e7f8;
  --secondary-color-300: #86d9ea;
  --secondary-color-400: #56d0e9;
  --secondary-color-500: #2abfe8;
  --secondary-color-600: #1eb0d9;
  --secondary-color-700: #129ec5;
  --secondary-color-800: #0b92b8;
  --secondary-color-900: #007292;
  --gray-color-25: #fcfdfd;
  --gray-color-50: #f9f9f9;
  --gray-color-100: #f4f4f4;
  --gray-color-200: #eee;
  --gray-color-300: #dedfdf;
  --gray-color-400: #b7b7b7;
  --gray-color-500: #878787;
  --gray-color-600: #676767;
  --gray-color-700: #575757;
  --gray-color-800: #373737;
  --gray-color-900: #171717;
  --white-color: #fff;
  --black-color: #000;
  --error-color-25: #fffbfa;
  --error-color-50: #fef3f2;
  --error-color-400: #fa7066;
  --error-color-500: #f04438;
  --warning-color-25: #fffcf5;
  --warning-color-50: #fffaeb;
  --warning-color-400: #fdb022;
  --warning-color-500: #f79009;
  --success-color-25: #f6fef9;
  --success-color-50: #ecfdf3;
  --success-color-400: #32d583;
  --success-color-500: #12b76a;
  --info-color-25: #f6fbff;
  --info-color-50: #eff8ff;
  --info-color-400: #468ff6;
  --info-color-500: #1570ef;
  --bs-font-sans-serif: $primary-font;
}
