// tables
.table-wrap {
  .tb-head-wrap {
    display: flex;
    margin-bottom: 1rem;

    > .search-wrap {
      margin-right: 1rem;

      .input-box {
        min-width: 14.5rem;
      }
    }

    > .tb-toolbar {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: flex-end;
      margin-left: auto;

      .btn-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: flex-end;
      }

      .btns {
        margin: 0;
        // margin-right: .5rem;
      }
    }
  }

  .tb-overflow {
    overflow-x: auto;
    word-break: keep-all;
  }
}

.table {
  color: var(--gray-color-700);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0;
  min-width: 45rem;
  text-align: center;

  tr {
    th,
    td {
      padding: 0.5rem 1rem;
      position: relative;

      .checkbox-wrap {
        margin: 0;
        min-height: 1.125rem;
        min-width: 1.125rem;

        > span {
          left: 0.0625rem;
          top: 0.25rem;
        }
      }

      .select-box select {
        min-width: 88px;
        padding-right: 2.5rem;
      }
    }
  }

  thead {
    background-color: var(--gray-color-50);
    border-bottom: 1px solid var(--gray-color-400);
    color: var(--gray-color-900);

    tr {
      font-weight: 600;

      th {
        border-bottom: none;
        height: 4.0625rem;
        vertical-align: middle;
        // border-right: 1px solid var(--gray-color-300);
        &:last-of-type {
          border-right: none;
        }

        &.sort {
          cursor: pointer;
          padding-right: 2.25rem;

          &:before {
            border-bottom: 0.3rem solid var(--gray-color-400);
            border-left: 0.3rem solid transparent;
            border-right: 0.3rem solid transparent;
            // vertical-align: 0.255em;
            border-top: 0;
            content: "";
            display: inline-block;
            position: absolute;
            right: 1rem;
            top: 41%;
          }

          &:after {
            border-bottom: 0;
            border-left: 0.3rem solid transparent;
            border-right: 0.3rem solid transparent;
            // vertical-align: 0.255em;
            border-top: 0.3rem solid var(--gray-color-400);
            content: "";
            display: inline-block;
            position: absolute;
            right: 1rem;
            top: 53%;
          }

          &.sort-up {
            &:before {
              border-bottom-color: var(--primary-color-400);
            }
          }

          &.sort-down {
            &:after {
              border-top-color: var(--primary-color-400);
            }
          }
        }
      }
    }

    &.multi {
      tr {
        th {
          height: 2rem;
        }

        &:last-of-type {
          th {
            background-color: var(--gray-color-200);
          }
        }
      }
    }
  }

  tbody {
    border-color: var(--gray-color-300);

    tr {
      &.ranking-first-secondary {
        background-color: var(--secondary-color-300);
      }

      &.ranking-second-secondary {
        background-color: var(--secondary-color-50);
      }

      &.ranking-first-primary {
        background-color: var(--primary-color-300);
      }

      &.ranking-second-primary {
        background-color: var(--primary-color-50);
      }
    }

    td {
      border-right: 1px solid var(--gray-color-300);
      height: 3.75rem;
      vertical-align: middle;

      &:last-of-type {
        border-right: none;
      }

      a {
        text-decoration: underline;
      }

      .edit-data {
        display: flex;
        min-width: 14.7rem;

        .input-box {
          margin-right: 0.25rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .thumbnail {
        background-color: var(--gray-color-200);
        background-image: url("/images/common/ico_thumb_default.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 0.875rem 0.875rem;
        border-radius: 0.5rem;
        height: 2.875rem;
        overflow: hidden;
        width: 5rem;

        > img {
          border-radius: 0.5rem;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }

  .btns {
    white-space: nowrap;

    &:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      content: "";
      display: inline-block;
      height: 0.75rem;
      margin-right: 0.25rem;
      position: relative;
      top: 0.0625rem;
      width: 0.75rem;
    }

    &.detail {
      &:before {
        background-image: url("/images/component/ico_search.png");
      }
    }

    &.reset {
      &:before {
        background-image: url("/images/component/ico_reset.png");
      }
    }

    &.attach {
      &:before {
        background-image: url("/images/content/icon_paperclip.png");
      }
    }

    &.index {
      &:before {
        background-image: url("/images/process/icon_depth.png");
      }
    }

    &.delete {
      &:before {
        background-image: url("/images/process/icon_trash.png");
      }
    }

    &.film {
      &:before {
        background-image: url("/images/process/icon_film.png");
      }
    }

    &.edit {
      &:before {
        background-image: url("/images/process/icon_edit.png");
      }
    }

    &.mail {
      &:before {
        background-image: url("/images/learning/icon_mail.png");
      }
    }

    &.printer {
      &:before {
        background-image: url("/images/learning/icon_printer.png");
      }
    }

    &.preview,
    &.setting {
      i {
        font-size: 0.75rem;
        margin-right: 0.25rem;
        position: relative;
        top: 0.0625rem;
      }

      &:before {
        content: none;
      }
    }
  }

  th,
  td {
    &.left {
      text-align: left;
    }
  }

  &.sm {
    thead {
      tr {
        th {
          font-size: 0.9375rem;
          height: 3.4375rem;
          padding: 0.375rem 0.75rem;

          &.sort {
            padding-right: 2rem;
          }
        }
      }
    }

    tbody {
      td {
        font-size: 0.9375rem;
        height: 3.0625rem;
        padding: 0.375rem 0.75rem;
      }
    }
  }
}

.modal-content {
  .table {
    min-width: 35rem;
  }
}

/*
* responsive
*/

// @media (width <= 575.98px) {
// }

// @media (width <= 767.98px) {
// }

// @media (width <= 991.98px) {
// }

@media (width <= 1199.98px) {
  .table-wrap {
    .tb-head-wrap {
      display: block;

      > .search-wrap {
        margin-bottom: 0.5rem;
        margin-right: 0;

        .input-box {
          min-width: 13.75rem;
        }
      }

      > .tb-toolbar {
        justify-content: space-between;
        margin-left: 0;

        .btn-wrap {
          justify-content: flex-start;
        }

        .select-box {
          margin-left: auto;
        }
      }
    }
  }
}

// @media (width <= 1399.98px) {
// }
